.slide-enter {
    transform: translateY(-100%);
}

.slide-enter-active {
    transform: translateY(0%);
    transition: all .5s ease;
}

.slide-exit-active {
    transform: translateY(-100%);
    transition: all .5s ease;
}